import React, { FC, useContext, useState } from 'react';
import { DataLines, Typography } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';
import { commify } from '@ethersproject/units';
import dayjs from 'dayjs';

import { CONTENT, CONTRACT, DEFAULT_CHAIN_ID, NETWORKS } from '@src/config';
import { BaseTokenSymbol } from '@src/contracts/index';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { EventType } from '@src/ts/constants';
import { ProjectEvent } from '@src/ts/interfaces';
import { parseBalance } from '@src/utils/web3';

import { useProjectContext } from '../../context';
import { InvestmentRound } from '../constants';
import { getRoundMultiplier } from '../InvestInput';

import { WhitelistCard } from './WhitelistCard';

export const EventRegister: FC<{
    event: ProjectEvent;
    answer?: string[];
}> = ({ event, answer = [] }) => {
    const { _userTier } = useContext(GlobalContext);
    const [accepted, setAccepted] = useState(false);

    const {
        registered,
        registerForWhitelist,
        whitelist_loading,
        user_allocation_wei,
    } = useProjectContext();

    const is_crowdfunding = event.type === EventType.Crowdfunding;

    const { symbol: payment_symbol, decimals } =
        CONTRACT.PaymentToken[event.chainId];

    const event_chain_id = event.chainId || DEFAULT_CHAIN_ID;

    if (!event?.contract?.abi || !event?.contract?.address) {
        return (
            <Typography variant="secondary">
                Registration for this event will open soon
            </Typography>
        );
    }

    const handleClick = () => registerForWhitelist(event.id, answer);

    const whitelist_props = {
        tier_img: _userTier?.image_url || CONTENT.tiers.NoTierImage,
        current_value: commify(_userTier.power.toFixed(2)),
        button_disabled: !accepted || whitelist_loading || registered,
        button_loading: whitelist_loading,
    };

    const { network_name, symbol: network_symbol } = NETWORKS[event_chain_id];

    const start = dayjs(Number(event.start_date) * 1000);

    const is_ga =
        dayjs().isBefore(
            start.add(event.event_details.durations[0], 'seconds'),
        ) && dayjs().isAfter(start);

    const fcfs_multiplier = getRoundMultiplier(InvestmentRound.FCFS);

    const fcfs_allocation =
        BigNumber.from(user_allocation_wei).mul(fcfs_multiplier);

    return (
        <div>
            {registered && is_crowdfunding && (
                <div className="mb-5">
                    <Typography size="sm" variant="secondary" className="mb-5">
                        You are successfully whitelisted for this event and
                        prepared to invest. Return to this page when the event
                        is live to proceed.
                    </Typography>
                    <div className="space-y-[10px]">
                        <DataLines
                            label="Network"
                            values={[{ value: network_name }]}
                        />
                        <DataLines
                            label="Network fees"
                            values={[{ value: network_symbol }]}
                        />
                        <DataLines
                            label="Funding currency"
                            values={[{ value: payment_symbol }]}
                        />
                    </div>
                </div>
            )}
            {is_ga && registered && (
                <DataLines
                    label={`FCFS round allocation`}
                    className="mb-2"
                    values={[
                        {
                            value: `${parseBalance(
                                fcfs_allocation,
                                decimals,
                            )} ${payment_symbol}`,
                        },
                    ]}
                />
            )}
            <WhitelistCard
                {...whitelist_props}
                toggle_terms={accepted}
                setToggleTerms={setAccepted}
                terms_url={CONTENT.termsAndConditions}
                saft_url={event.saft_url || CONTENT.saft}
                handleClick={handleClick}
                token_symbol={BaseTokenSymbol}
                whitelisted={registered}
            />
        </div>
    );
};
