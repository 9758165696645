import { useContext } from 'react';
import { DataLines } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';
import dayjs from 'dayjs';

import { CONTRACT, DEFAULT_CHAIN_ID, NETWORKS } from '@src/config';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { bnFormatter } from '@src/utils/format';
import { parseBalance, removeCommas } from '@src/utils/web3';

import { useProjectContext } from '../../context';
import { InvestmentRound } from '../constants';

import { InvestButtons } from './InvestButtons';
import { Input } from './InvestInput';

export const getRoundMultiplier = (round: InvestmentRound) => {
    switch (round) {
        case InvestmentRound.FCFS2:
            return 10;
        case InvestmentRound.FCFS:
            return 2;
        default:
            return 1;
    }
};

export const InvestInput: React.FC<{
    round: InvestmentRound;
    payment_balance: string;
}> = ({ round, payment_balance }) => {
    const { _userTier } = useContext(GlobalContext);
    const {
        event,
        crowdfunding: { total_raised, hardcap, user_investment } = {
            total_raised: '0',
            hardcap: '0',
            user_investment: '0',
        },
        user_allocation_wei,
        invest_amount,
    } = useProjectContext();

    const start = dayjs(Number(event?.start_date) * 1000);
    const should_display_alloc = dayjs().isAfter(start);
    const event_chain_id = event.chainId || DEFAULT_CHAIN_ID;

    const { symbol, decimals } = CONTRACT.PaymentToken[event_chain_id];

    const invest_amount_wei = parseUnits(
        removeCommas(invest_amount || '0'),
        decimals,
    );

    const multiplier = getRoundMultiplier(round);

    const fcfs_multiplier = getRoundMultiplier(InvestmentRound.FCFS);

    const fcfs_allocation = BigNumber.from(user_allocation_wei)
        .mul(fcfs_multiplier)
        .sub(user_allocation_wei);

    const round_allocation =
        BigNumber.from(user_allocation_wei).mul(multiplier);

    const more_than_allocated = invest_amount_wei.gt(
        round_allocation.sub(user_investment),
    );

    const more_than_available = invest_amount_wei.gt(
        BigNumber.from(payment_balance),
    );

    const has_invested = BigNumber.from(user_investment).gt(0);
    const alloc_loaded = _userTier !== null;

    const event_network = NETWORKS[event_chain_id];

    return (
        <div>
            <div className="text-lg mb-4 space-y-1">
                <DataLines
                    label={`${
                        has_invested
                            ? 'Remaining'
                            : round === InvestmentRound.GA
                            ? 'Guaranteed'
                            : 'Your'
                    }  allocation`}
                    values={[
                        {
                            value: should_display_alloc
                                ? `${parseBalance(
                                      round_allocation.sub(user_investment),
                                      decimals,
                                  )} ${symbol}`
                                : 'TBA',
                            color: more_than_allocated ? 'text-error' : '',
                        },
                    ]}
                    loaded={alloc_loaded}
                />

                {round === InvestmentRound.GA && (
                    <DataLines
                        label={`FCFS round allocation`}
                        values={[
                            {
                                value: should_display_alloc
                                    ? `${parseBalance(
                                          fcfs_allocation,
                                          decimals,
                                      )} ${symbol}`
                                    : 'TBA',
                            },
                        ]}
                        loaded={alloc_loaded}
                    />
                )}
            </div>

            <Input
                disabled={!should_display_alloc}
                total_raised={total_raised}
                hardcap={hardcap}
                user_allocation={round_allocation.toString()}
                user_investment={user_investment}
                payment_amount={payment_balance}
                payment_decimals={decimals}
                payment_symbol={symbol}
                disable_max={!should_display_alloc}
            />

            <div className="my-4 space-y-1 text-lg">
                <DataLines
                    label={'Total invested'}
                    values={[
                        {
                            value: `${parseBalance(
                                user_investment || 0,
                                decimals,
                            )} ${symbol}`,
                        },
                    ]}
                    loaded={alloc_loaded}
                />
                <DataLines
                    label={`${symbol} balance on ${event_network.network_name}`}
                    values={[
                        {
                            value: `${bnFormatter(
                                payment_balance || 0,
                                decimals,
                            )} ${symbol}`,
                            color: more_than_available ? 'text-error' : '',
                        },
                    ]}
                    loaded={alloc_loaded}
                />
            </div>
            <InvestButtons
                invest_amount={invest_amount_wei.toString()}
                more_than_allocated={more_than_allocated}
                more_than_available={more_than_available}
            />
        </div>
    );
};
