import dayjs from 'dayjs';

import { DEFAULT_CHAIN_ID, NETWORKS } from '@src/config';
import { PaymentTokenSymbol } from '@src/contracts/index';
import { EventType } from '@src/ts/constants';
import { ProjectEvent, ProjectToken } from '@src/ts/interfaces';
import { getDuration } from '@src/utils/getters';

export const getOverviewItems = (token: ProjectToken, event?: ProjectEvent) => {
    const { tge, ticker, network } = token;
    const { refund_deadline, start_date } = event || {};

    const network_to_display =
        NETWORKS[event?.chainId]?.network_name ||
        Object.values(NETWORKS).find((n) => n.identifier === network)
            ?.network_name ||
        NETWORKS[DEFAULT_CHAIN_ID].network_name;

    return [
        {
            value: ticker,
            label: 'Token ticker',
            type: [EventType.Crowdfunding, EventType.TokenClaim],
        },
        {
            value: network_to_display,
            label: 'Network',
            type: [EventType.Crowdfunding, EventType.TokenClaim],
        },
        {
            value:
                (start_date &&
                    dayjs(Number(start_date) * 1000).format('DD MMM, YYYY')) ||
                'TBA',
            label: 'Event date',
            type: [EventType.Crowdfunding, EventType.TokenClaim],
        },
        {
            value: tge,
            label: 'Token listing',
            type: [EventType.Crowdfunding, EventType.TokenClaim],
        },
        {
            value: event
                ? refund_deadline
                    ? getDuration(refund_deadline)
                    : 'None'
                : 'TBA',
            label: 'Refund duration',
            type: [EventType.Crowdfunding],
        },
        {
            value: PaymentTokenSymbol(event?.chainId),
            label: 'Accepted currency',
            type: [EventType.Crowdfunding, EventType.TokenClaim],
        },
    ].filter(({ value, type }) => !!value || type.includes(event?.type));
};
