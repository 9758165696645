import React, { useMemo } from 'react';
import { Typography } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';
import { commify, formatUnits } from '@ethersproject/units';
import dayjs from 'dayjs';

import { Card, Chevron } from '@src/components';
import NetworkIcon from '@src/components/NetworkIcon';
import { DEFAULT_CHAIN_ID } from '@src/config';
import { InvestmentItemProps } from '@src/ts/interfaces';
import {
    bnFormatter,
    formatRelevantDecimals,
    nFormatter,
} from '@src/utils/format';

import { UNSUPPORTED_VESTING_LINKS } from './constants';
import { Dropdown } from './Dropdown';

const vesting_links = UNSUPPORTED_VESTING_LINKS[DEFAULT_CHAIN_ID] || {};

const Pill: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
    <div className="rounded-full border border-accent text-accent px-3 py-1 text-xs max-w-min">
        {children}
    </div>
);

const DynamicText: React.FC<{
    text?: string | React.ReactNode;
    title: string;
}> = ({ title, text }) => {
    return (
        <div className="md:col-span-1 flex items-center flex-row-reverse md:flex-row">
            <div className="">{text || <strong>TBD</strong>}</div>
            <div className="inline md:hidden text-secondary flex-1">
                {title}
            </div>
        </div>
    );
};

const mobile = `inline md:hidden`;
const desktop = 'hidden md:inline';

const getStatus = (
    vesting_active: boolean,
    has_external_link: boolean,
    refunded: boolean,
    claimed_all: boolean,
    vesting_started: boolean,
) => {
    if (has_external_link) return 'Claimable';
    if (refunded) return 'Refunded';
    if (!vesting_active || !vesting_started) return 'Pending';
    if (claimed_all) return 'Claimed';
    return 'Claimable';
};

export const InvestmentItem: React.FC<InvestmentItemProps> = (props) => {
    const {
        invest_amount,
        name,
        token_price,
        token_symbol,
        total,
        is_airdrop,
        vesting_active,
        contract_address,
        decimals,
        refunded,
        released,
        available,
        start_date,
        payment_decimals,
        payment_symbol,
        refund_deadline,
        token_change,
        live_token_price,
        invest_is_open,
        setInvestOpen,
        chainId,
    } = props;

    const has_external_link = !!vesting_links[contract_address];

    const estimate = Number(formatUnits(invest_amount, decimals)) / token_price;

    const ExpandButton = () => (
        <button
            className="text-secondary"
            onClick={() => {
                if (invest_is_open) setInvestOpen(false);
            }}
        >
            <Chevron
                type={invest_is_open ? 'up' : 'down'}
                height={'10'}
                width={'10'}
            />
        </button>
    );

    const cardOnClick = () => {
        if (has_external_link) {
            window.open(vesting_links[contract_address]);
            return;
        }
        if (vesting_active && !refunded && !invest_is_open) setInvestOpen(true);
    };

    const claimed_all =
        BigNumber.from(total || '1').eq(released || '0') &&
        BigNumber.from(available || '0').eq(0);

    const vesting_started = useMemo(
        () => dayjs().isAfter(start_date),
        [start_date],
    );

    return (
        <Card
            className="px-4 md:px-5 py-4 bg-surface-level-one mb-4 cursor-pointer min-h-[82px]"
            onClick={cardOnClick}
        >
            <div className="min-h-[40px] grid grid-cols-1 md:grid-cols-15 w-full flex-1 gap-[10px] items-center mb-2 md:mb-0">
                {/* Event name */}
                <div className="text-2xl md:text-base flex items-center col-span-3">
                    <div className="flex-1 flex items-center space-x-3">
                        <div className="flex items-center space-x-4">
                            <Typography allBold>{name}</Typography>
                            <div className="md:hidden inline">
                                <NetworkIcon chain_id={chainId} />
                            </div>
                        </div>

                        {refund_deadline > 0 && (
                            <img
                                alt="protected invetment"
                                src="https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Protected-Green.svg"
                                className="w-8 h-8"
                            />
                        )}
                    </div>
                    {vesting_active && !refunded && (
                        <div className={mobile}>
                            <ExpandButton />
                        </div>
                    )}
                    {has_external_link && (
                        <div className={mobile}>
                            <button className="text-secondary">
                                <Chevron
                                    type={'right'}
                                    height={'10'}
                                    width={'10'}
                                />
                            </button>
                        </div>
                    )}
                </div>
                {/* Network  */}
                <div className="pl-2 hidden md:inline col-span-1">
                    <NetworkIcon chain_id={chainId} />
                </div>
                {/* Invested */}
                <div
                    className={`col-span-2 ${
                        is_airdrop ? 'md:inline hidden' : ''
                    }`}
                >
                    <DynamicText
                        text={
                            is_airdrop ? (
                                <Pill>N/A</Pill>
                            ) : (
                                `${bnFormatter(
                                    invest_amount,
                                    payment_decimals,
                                )} ${payment_symbol}`
                            )
                        }
                        title="Invested"
                    />
                </div>
                {/* Tokens */}
                <div className="col-span-2">
                    {' '}
                    <DynamicText
                        text={`${
                            total
                                ? bnFormatter(total, decimals, 2)
                                : nFormatter(estimate, 2)
                        } ${token_symbol}`}
                        title="Tokens"
                    />
                </div>
                {/* Status */}
                <div
                    className={`col-span-2 ${
                        has_external_link
                            ? 'hidden md:flex'
                            : 'flex justify-between'
                    }`}
                >
                    {!has_external_link && (
                        <div className="inline md:hidden text-secondary">
                            Status
                        </div>
                    )}
                    {getStatus(
                        vesting_active,
                        has_external_link,
                        refunded,
                        claimed_all,
                        vesting_started,
                    )}
                </div>
                {/* Purchase price */}
                <div
                    className={`col-span-2 flex justify-between ${
                        is_airdrop ? 'md:inline hidden' : ''
                    }`}
                >
                    <div className="inline md:hidden text-secondary">
                        Purchase price
                    </div>
                    {!is_airdrop ? (
                        <div>{`$${formatRelevantDecimals(
                            token_price,
                            2,
                        )}`}</div>
                    ) : (
                        <div>
                            <Pill>N/A</Pill>
                        </div>
                    )}
                </div>

                {/* Live price */}
                <div className={`col-span-2 flex justify-between w-full`}>
                    <div className="inline md:hidden text-secondary">
                        Live price
                    </div>
                    {live_token_price ? (
                        <div className="flex space-x-1">
                            <Typography>
                                {`$${formatRelevantDecimals(
                                    live_token_price,
                                    2,
                                )}`}
                            </Typography>
                            {live_token_price &&
                                !is_airdrop &&
                                token_change && (
                                    <Typography
                                        variant="custom"
                                        className={`${
                                            token_change > 0
                                                ? 'text-success'
                                                : 'text-error'
                                        }`}
                                    >
                                        {`(${
                                            token_change > 0 ? '+' : ''
                                        }${commify(token_change.toFixed(0))}%)`}
                                    </Typography>
                                )}
                        </div>
                    ) : (
                        <Typography>Pending</Typography>
                    )}
                </div>
                {/* Action */}
                <div
                    className={`flex-row-reverse md:flex-row justify-end col-span-1 ${
                        has_external_link ? 'hidden md:flex' : 'flex'
                    }`}
                >
                    <div>
                        {vesting_active && !refunded && !has_external_link && (
                            <div
                                className={`text-secondary float-right ${desktop}`}
                            >
                                <ExpandButton />
                            </div>
                        )}
                        {has_external_link && (
                            <div
                                className={`float-right flex h-full items-center justify-center text-secondary ${desktop} text-secondary`}
                            >
                                <Chevron
                                    type={'right'}
                                    height={'10'}
                                    width={'10'}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {invest_is_open && !refunded && <Dropdown {...props} />}
        </Card>
    );
};
