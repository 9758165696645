import { pContent, pContracts } from '../dcb-config';

import { Content, ContractConfig } from './ts/interfaces';
import { legacy_staking_versions } from './ts/types';
import { staking_contract_versions } from './constants';

const getStakingChainIds = (contracts: ContractConfig) => {
    const all_staking_version = Object.keys(staking_contract_versions);
    const legacy_versions = Array.from(legacy_staking_versions) as string[];
    const multichain_version = all_staking_version.filter(
        (v) => !legacy_versions.includes(v),
    );
    const all_chain_ids = [];
    for (const version of multichain_version) {
        const contracts_for_version =
            contracts[staking_contract_versions[version]];

        if (contracts_for_version) {
            all_chain_ids.push(
                ...Object.keys(contracts_for_version).map(Number),
            );
        }
    }

    const unique_chain_ids = new Set(all_chain_ids);

    return Array.from(unique_chain_ids);
};

export const DEFAULT_CHAIN_ID = parseInt(process.env.REACT_CHAIN_ID, 10) || 97;

// Load project content
export const CONTENT: Content = pContent as Content;

export const BASE_IMAGE_URL = CONTENT.baseImgUrl || '';

// Load project contracts
export const CONTRACT: ContractConfig = pContracts as ContractConfig;

export const HAS_LEGACY_INVESTMENTS = !!CONTRACT.Investments;
export const HAS_LEGACY_EVENT_FACTORY = !!CONTRACT.LegacyEventFactory;

export const isLegacyStakingType = (type: string) =>
    (Array.from(legacy_staking_versions) as string[]).includes(type);

//TODO: handle legacy and non-legacy event factorys
export const EVENT_FACTORY_CHAIN_IDS = Object.keys(CONTRACT.EventFactory).map(
    (key) => parseInt(key, 10),
);

export const STAKING_CHAIN_IDS = getStakingChainIds(CONTRACT);

export const ALL_CHAIN_IDS = Array.from(
    new Set([...EVENT_FACTORY_CHAIN_IDS, ...STAKING_CHAIN_IDS]),
);

export const DECUBATE_UI_THEME =
    JSON.parse(process.env.DECUBATE_UI_THEME || '{}') || {};

export const USES_LEGACY_STAKING = !!CONTRACT.LegacyVault;

export const HAS_NFT = !!CONTRACT.NFT;

const getRandomInt = (max: number) => Math.floor(Math.random() * max);

// let this take in a chainId and return the rpcUrl
export const getRPCUrl = (chainId?: number): string => {
    if ([null, undefined].includes(chainId)) chainId = DEFAULT_CHAIN_ID;

    const url_list = NETWORKS[chainId || DEFAULT_CHAIN_ID]?.rpc || [];
    return url_list[getRandomInt(url_list.length)];
};

export const GRAPHQL_ENDPOINT =
    process.env.REACT_GRAPHQL_ENDPOINT ||
    'https://0ig9c0tdra.execute-api.eu-central-1.amazonaws.com/dev/graphql';

export const IS_PROD = GRAPHQL_ENDPOINT.includes('prod');

export const RECAPTCHA_ID = CONTENT.recaptchaId || '';

export const NETWORKS: {
    [id: number]: {
        rpc: string[];
        symbol: string;
        explorer_url: string;
        network_name: string;
        network_logo?: string;
        identifier: string;
        testnet?: boolean;
    };
} = {
    56: {
        rpc: [
            'https://bsc-dataseed1.binance.org',
            'https://bsc-dataseed.bnbchain.org',
            'https://bsc-dataseed1.defibit.io',
            // 'https://bsc.decubate.com',
        ],
        symbol: 'BNB',
        explorer_url: 'bscscan.com',
        network_name: 'BNB Chain',
        network_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/network-logos/binance_logo_light.svg',
        identifier: 'bsc',
    },
    97: {
        rpc: [
            'https://data-seed-prebsc-1-s1.binance.org:8545/',
            'https://data-seed-prebsc-1-s2.binance.org:8545',
        ],
        symbol: 'BNB',
        explorer_url: 'testnet.bscscan.com',
        network_name: 'BSC Testnet',
        network_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/network-logos/binance_logo_light.svg',
        identifier: 'bsc',
        testnet: true,
    },
    137: {
        rpc: [
            'https://polygon-mainnet.public.blastapi.io',
            'https://poly-mainnet.rpc.grove.city/v1/176a6bca',
            'https://polygon-rpc.com',
        ],
        symbol: 'MATIC',
        explorer_url: 'polygonscan.com',
        network_name: 'Polygon',
        network_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/network-logos/polygon_logo_light.svg',
        identifier: 'polygon',
    },
    80001: {
        rpc: [
            // 'https://rpc.ankr.com/polygon_mumbai',
            'https://polygon-mumbai-bor.publicnode.com',
        ],
        symbol: 'MATIC',
        explorer_url: 'mumbai.polygonscan.com',
        network_name: 'Polygon Mumbai',
        network_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/network-logos/polygon_logo_light.svg',
        identifier: 'polygon',
        testnet: true,
    },
    1: {
        rpc: [
            'https://ethereum.publicnode.com',
            'https://cloudflare-eth.com',
            'https://rpc.ankr.com/eth',
        ],
        symbol: 'ETH',
        explorer_url: 'etherscan.com',
        network_name: 'Ethereum',
        identifier: 'ethereum',
        network_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/network-logos/eth_logo_light.svg',
    },
    3: {
        rpc: ['https://ropsten.infura.io/v3/6e421ace3971407eb0ad0c12c233db4a'],
        symbol: 'ETH',
        explorer_url: 'ropsten.etherscan.com',
        network_name: 'Ropsten',
        identifier: 'ethereum',
        network_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/network-logos/eth_logo_light.svg',
        testnet: true,
    },
    4: {
        rpc: ['https://rinkeby.infura.io/v3/6e421ace3971407eb0ad0c12c233db4a'],
        symbol: 'ETH',
        explorer_url: 'rinkeby.etherscan.com',
        network_name: 'Rinkeby',
        identifier: 'ethereum',
        network_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/network-logos/eth_logo_light.svg',
        testnet: true,
    },
    5: {
        rpc: [
            //  'wss://ethereum-goerli-rpc.publicnode.com',
            // 'https://goerli.blockpi.network/v1/rpc/public',
            //  'https://ethereum-goerli.publicnode.com	',
        ],
        symbol: 'ETH',
        explorer_url: 'georli.etherscan.com',
        network_name: 'Georli',
        identifier: 'ethereum',
        network_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/network-logos/eth_logo_light.svg',
        testnet: true,
    },
    59144: {
        rpc: [
            'https://linea-mainnet.infura.io/v3/06abce7b60024dc5a173c1a997741ed9',
        ],
        symbol: 'ETH',
        explorer_url: 'www.etherscan.com',
        network_name: 'Linea',
        identifier: 'linea',
        network_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/linea_icon.svg',
    },
    59140: {
        rpc: ['https://rpc.goerli.linea.build'],
        symbol: 'ETH',
        explorer_url: 'explorer.goerli.linea.build',
        network_name: 'Linea Testnet',
        identifier: 'linea',
        network_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/linea_icon.svg',
        testnet: true,
    },
    42161: {
        rpc: [
            'https://arbitrum-one.publicnode.com',
            'https://rpc.ankr.com/arbitrum',
        ],
        symbol: 'ETH',
        explorer_url: 'arbiscan.io',
        network_name: 'Arbitrum',
        identifier: 'arbitrum',
        network_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/network-logos/arbitrum_logo.svg',
    },
    1116: {
        rpc: [
            'https://rpc.ankr.com/core',
            'https://core.public.infstones.com',
            'https://rpc.coredao.org',
        ],
        symbol: 'CORE',
        explorer_url: 'scan.coredao.org',
        network_name: 'Core',
        identifier: 'core',
        network_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/network-logos/core.svg',
    },
    8453: {
        rpc: [
            'https://base.gateway.tenderly.co',
            'https://base.drpc.org',
            'https://base-rpc.publicnode.com',
            'https://base-mainnet.public.blastapi.io',
        ],
        symbol: 'ETH',
        explorer_url: 'basescan.org',
        network_name: 'Base',
        identifier: 'base',
        network_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/network-logos/base_icon.svg',
    },
    43114: {
        rpc: [
            'https://avalanche.drpc.org',
            'https://avalanche.public-rpc.com',
            'https://ava-mainnet.public.blastapi.io/ext/bc/C/rpc',
            'https://1rpc.io/avax/c',
        ],
        symbol: 'AVAX',
        explorer_url: 'snowtrace.io',
        network_name: 'Avalanche',
        identifier: 'avalanche',
        network_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/network-logos/avax.svg',
    },
    84532: {
        rpc: [
            'https://base-sepolia.blockpi.network/v1/rpc/public',
            'https://base-sepolia-rpc.publicnode.com',
            'https://base-sepolia-rpc.publicnode.com',
        ],
        symbol: 'ETH',
        explorer_url: 'sepolia.basescan.org',
        network_name: 'Base Sepolia Testnet',
        identifier: 'base',
        testnet: true,
        network_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/network-logos/base_icon.svg',
    },
    11155111: {
        rpc: [
            'https://eth-sepolia.public.blastapi.io',
            'https://eth-sepolia-public.unifra.io',
        ],
        symbol: 'ETH',
        explorer_url: 'sepolia.etherscan.com',
        network_name: 'Sepolia',
        identifier: 'ethereum',
        network_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/network-logos/eth_logo_light.svg',
        testnet: true,
    },
};
