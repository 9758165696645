import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';

import { IContractManager } from '@src/contracts';
import { ContractType } from '@src/ts/constants';
import { UpdateableStakingValues } from '@src/ts/interfaces';

export const getCompoundPool = async (
    id: number,
    account: string,
    staking: Contract,
    chain_id: number,
    contract_manager: IContractManager,
): Promise<UpdateableStakingValues> => {
    const { contract: compounder } = contract_manager.getContract(
        ContractType.Vault,
        chain_id,
    );

    const [pool, [multi], user, [reward], [can_claim]] =
        await contract_manager.multicall(
            [
                {
                    func_name: 'poolInfo',
                    target: staking.address,
                    iface: staking.interface,
                    params: [id],
                },
                {
                    func_name: 'calcMultiplier',
                    target: staking.address,
                    iface: staking.interface,
                    params: [id, account],
                },
                {
                    func_name: 'users',
                    target: compounder.address,
                    iface: compounder.interface,
                    params: [id, account],
                },
                {
                    func_name: 'getRewardOfUser',
                    target: compounder.address,
                    iface: compounder.interface,
                    params: [account, id],
                },
                {
                    func_name: 'canUnstake',
                    target: compounder.address,
                    iface: compounder.interface,
                    params: [account, id],
                },
            ],
            { chain_id },
        );

    const ts = user.lastDepositedTime.mul(1000).toNumber();
    const last_user_deposit = ts !== 0 ? new Date(ts).toISOString() : null;
    return {
        total_staked: [pool.totalDeposit.toString()],
        user_stake: [user.totalInvested.toString()],
        earned_reward: reward.toString(),
        last_user_deposit,
        can_claim,
        has_stake: BigNumber.from(user.totalInvested).gt(0),
        user_shares: user.shares.toString(),
        user_multiplier: multi.toString(),
    };
};

export const getLegacyCompoundPool = async (
    id: number,
    account: string,
    staking: Contract,
    contract_manager: IContractManager,
): Promise<UpdateableStakingValues> => {
    const { contract: compounder } = contract_manager.getContract(
        ContractType.LegacyVault,
    );

    const [pool, [multi], user, [reward], [can_claim]] =
        await contract_manager.multicall([
            {
                func_name: 'poolInfo',
                target: staking.address,
                iface: staking.interface,
                params: [id],
            },
            {
                func_name: 'calcMultiplier',
                target: staking.address,
                iface: staking.interface,
                params: [id, account],
            },
            {
                func_name: 'users',
                target: compounder.address,
                iface: compounder.interface,
                params: [id, account],
            },
            {
                func_name: 'getRewardOfUser',
                target: compounder.address,
                iface: compounder.interface,
                params: [account, id],
            },
            {
                func_name: 'canUnstake',
                target: compounder.address,
                iface: compounder.interface,
                params: [account, id],
            },
        ]);

    const ts = user.lastDepositedTime.mul(1000).toNumber();
    const last_user_deposit = ts !== 0 ? new Date(ts).toISOString() : null;
    return {
        total_staked: [pool.totalDeposit.toString()],
        user_stake: [user.totalInvested.toString()],
        earned_reward: reward.toString(),
        last_user_deposit,
        can_claim,
        has_stake: BigNumber.from(user.totalInvested).gt(0),
        user_shares: user.shares.toString(),
        user_multiplier: multi.toString(),
    };
};

export const getLegacyPool = async (
    id: number,
    account: string,
    staking: Contract,
    contract_manager: IContractManager,
): Promise<UpdateableStakingValues> => {
    const [pool, user, [reward], [can_claim]] =
        await contract_manager.multicall(
            [
                {
                    func_name: 'poolInfo',
                    target: staking.address,
                    params: [id],
                },
                {
                    func_name: 'users',
                    target: staking.address,
                    params: [id, account],
                },
                {
                    func_name: '_payout',
                    target: staking.address,
                    params: [id, account],
                },
                {
                    func_name: 'canClaim',
                    target: staking.address,
                    params: [id, account],
                },
            ],
            { default_iface: staking.interface },
        );

    const user_stake = user[0];
    const ts = user.depositTime.mul(1000).toNumber();
    const last_user_deposit = ts !== 0 ? new Date(ts).toISOString() : null;

    return {
        total_staked: [pool?.totalDeposit.toString()],
        user_stake: [user_stake.toString()],
        earned_reward: reward.toString(),
        claimed_reward: user.totalClaimed.toString(),
        last_user_deposit,
        can_claim,
        has_stake: BigNumber.from(user_stake).gt(0),
        user_multiplier: '10',
        is_reward_above_input: false,
    };
};

export const getMultiAssetPool = async (
    id: number,
    account: string,
    staking: Contract,
    contract_manager: IContractManager,
): Promise<UpdateableStakingValues> => {
    const [pool, [user_multiplier], user, [reward], [can_claim]] =
        await contract_manager.multicall(
            [
                {
                    func_name: 'poolInfo',
                    target: staking.address,
                    params: [id],
                },
                {
                    func_name: 'calcMultiplier',
                    target: staking.address,
                    params: [id, account],
                },
                {
                    func_name: 'users',
                    target: staking.address,
                    params: [id, account],
                },
                {
                    func_name: 'payout',
                    target: staking.address,
                    params: [id, account],
                },
                {
                    func_name: 'canUnstake',
                    target: staking.address,
                    params: [id, account],
                },
            ],
            { default_iface: staking.interface },
        );

    const user_stake = user[0];
    const ts = user.depositTime.mul(1000).toNumber();
    const last_user_deposit = ts !== 0 ? new Date(ts).toISOString() : null;

    return {
        total_staked: [pool?.totalDeposit.toString()],
        user_stake: [user_stake.toString()],
        earned_reward: reward.toString(),
        claimed_reward: user.totalClaimed.toString(),
        last_user_deposit,
        can_claim,
        has_stake: BigNumber.from(user_stake).gt(0),
        user_multiplier: user_multiplier.toString(),
        ratio: pool.ratio?.toString() || '1',
    };
};

export const getLiquidityPool = async (
    id: number,
    account: string,
    staking: Contract,
    chain_id: number,
    contract_manager: IContractManager,
): Promise<UpdateableStakingValues> => {
    const [pool, [user_multiplier], user, [reward], [can_claim]] =
        await contract_manager.multicall(
            [
                {
                    func_name: 'pools',
                    target: staking.address,
                    params: [id],
                },
                {
                    func_name: 'calcMultiplier',
                    target: staking.address,
                    params: [id, account],
                },
                {
                    func_name: 'users',
                    target: staking.address,
                    params: [id, account],
                },
                {
                    func_name: 'payout',
                    target: staking.address,
                    params: [id, account],
                },
                {
                    func_name: 'canClaim',
                    target: staking.address,
                    params: [id, account],
                },
            ],
            { default_iface: staking.interface, chain_id },
        );

    const pair = contract_manager.getContractByAddress(
        pool.input,
        ContractType.UniswapV2Pair,
    );

    const [reserves, [total_lps]] = await contract_manager.multicall(
        [
            {
                func_name: 'getReserves',
                target: pair.contract.address,
            },
            {
                func_name: 'totalSupply',
                target: pair.contract.address,
            },
        ],
        { default_iface: pair.contract.interface, chain_id },
    );

    const ts = user.depositTime * 1000;
    const last_user_deposit = ts !== 0 ? new Date(ts).toISOString() : null;

    const token0_amount = reserves.reserve0
        .mul(pool.totalInvested)
        .div(total_lps);
    const token1_amount = reserves.reserve1
        .mul(pool.totalInvested)
        .div(total_lps);

    const user_token0_amount = reserves.reserve0
        .mul(user.totalInvested)
        .div(total_lps);
    const user_token1_amount = reserves.reserve1
        .mul(user.totalInvested)
        .div(total_lps);

    return {
        total_staked: [token0_amount, token1_amount],
        user_stake: [user_token0_amount, user_token1_amount],
        earned_reward: reward.toString(),
        claimed_reward: user.totalClaimed.toString(),
        last_user_deposit,
        can_claim,
        has_stake: user.totalInvested.gt(0),
        user_multiplier: user_multiplier.toString(),
        lp_amount: user.totalInvested.toString(),
    };
};
