import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';

import { api_client } from '@src/bootstrap';
import { PROJECT } from '@src/services/project';
import { StakingPool } from '@src/ts/interfaces';

interface ReducedPools {
    [token_addr: string]: {
        earned_rewards: BigNumber;
        total_staked: BigNumber;
        user_stake: BigNumber;
    };
}

export const getTokenPrice = async (
    symbol: string,
    token_addr?: string,
): Promise<number> => {
    try {
        const { getTokenPrice: price } = ((await api_client.query({
            query: PROJECT.GET_TOKEN_PRICE,
            variables: { symbol, token_addr },
        })) as { getTokenPrice: string }) || { getTokenPrice: 0 };

        return Number(price);
    } catch (error) {
        return 0;
    }
};

export const getTokenPrices = async (data: {
    [symbol: string]: unknown;
}): Promise<{ [symbol: string]: number }> => {
    return (
        await Promise.all(
            // loop through keys in data from reduced pools (each token address)
            Object.keys(data).map(async (symbol) => [
                symbol,
                await getTokenPrice(symbol),
            ]),
        )
    ).reduce(
        (acc, [sym, price]) => ({ ...acc, [sym]: price }), // reduce array to object in the format { [symbol]: price }
        {},
    );
};

export const weiToNumber = (wei: string): number =>
    Number(formatUnits(wei, 18).toString());

export const reducePools = (pools: StakingPool[]): ReducedPools =>
    pools.reduce(
        (
            totals,
            {
                input_token,
                reward_token: { symbol: reward_symbol },
                claimed_reward = 0,
                earned_reward = 0,
                user_stake = [0, 0],
                total_staked = [0, 0],
            },
        ) => {
            input_token.forEach(({ symbol: input_symbol }, idx) => {
                // if input token object exists
                if (totals[input_symbol]) {
                    // add to total staked and user stake
                    totals[input_symbol] = {
                        ...totals[input_symbol],
                        total_staked: totals[input_symbol].total_staked.add(
                            total_staked[idx],
                        ),
                        user_stake: totals[input_symbol].user_stake.add(
                            user_stake[idx],
                        ),
                    };
                } else {
                    // otherwise initialise object with values
                    totals[input_symbol] = {
                        earned_rewards: BigNumber.from(0),
                        total_staked: BigNumber.from(total_staked[idx]),
                        user_stake: BigNumber.from(user_stake[idx]),
                    };
                }
            });

            // if reward token exists
            if (totals[reward_symbol]) {
                // add to earned rewards
                totals[reward_symbol] = {
                    ...totals[reward_symbol],
                    earned_rewards: totals[reward_symbol].earned_rewards
                        .add(claimed_reward)
                        .add(earned_reward),
                };
            } else {
                // otherwise initialise object with values
                totals[reward_symbol] = {
                    earned_rewards:
                        BigNumber.from(claimed_reward).add(earned_reward),
                    total_staked: BigNumber.from(0),
                    user_stake: BigNumber.from(0),
                };
            }
            return totals;
        },
        {},
    );

export const aggregateValues = (
    key: string,
    pools: {
        [key: string]: {
            earned_rewards: BigNumber;
            total_staked: BigNumber;
            user_stake: BigNumber;
        };
    },
    prices: Record<string, number>,
): number => {
    const value = Object.keys(pools)
        .map((addr) => {
            const total = weiToNumber(pools[addr][key].toString());
            const price = prices[addr] || 0;
            return total * price;
        })
        .reduce((total, current) => total + current, 0)
        .toFixed(2);
    return Number(value);
};
