import React, { useMemo } from 'react';
import { BreakPoint, Typography, useBreakpoint } from '@decub8/ui';
import dayjs from 'dayjs';

import {
    EXCHANGE_NAMES,
    getExchangeData,
} from '@src/components/Layout/Header/hooks/utils';
import { EventType } from '@src/ts/constants';
import { ProjectEvent, ProjectToken } from '@src/ts/interfaces';
import { formatTime } from '@src/utils/format';

const getVestingDetails = (token: ProjectToken, event?: ProjectEvent) => {
    const {
        type,
        event_details: { vesting },
        token_price,
    } = event || { event_details: {} };

    const start_date = vesting ? new Date(vesting.start * 1000) : null;

    const formatted_start_date = start_date
        ? dayjs(start_date).utc().format('MMM D, YYYY | HH:mm UTC')
        : null;

    return [
        {
            value: !isNaN(vesting?.initial_unlock_percent)
                ? `${vesting?.initial_unlock_percent / 10}%`
                : 'TBA',
            label:
                event?.type === EventType.TokenClaim
                    ? 'Release on vesting start'
                    : 'Release on CEX listing',
        },
        {
            value: formatted_start_date || 'TBA',
            label:
                event?.type === EventType.TokenClaim
                    ? 'Vesting start'
                    : 'CEX listing',
        },
        {
            value: !isNaN(vesting?.cliff) ? formatTime(vesting?.cliff) : 'TBA',
            label: 'Cliff duration',
        },

        {
            value:
                type === EventType.TokenClaim
                    ? token_price
                    : token_price
                    ? `${token_price} USD`
                    : 'TBA',
            label: 'Event token price',
        },
    ].filter(({ value }) => !!value);
};

const VestingDetails: React.FC<{
    token: ProjectToken;
    event: ProjectEvent;
    release_CEXListing?: number | null;
}> = ({ token, event }) => {
    const vesting_details = useMemo(
        () => getVestingDetails(token, event),
        [token, event],
    );

    const breakpoint = useBreakpoint();
    const is_mobile = breakpoint < BreakPoint.SM;

    const exchange_name = EXCHANGE_NAMES[0];
    const {
        is_dex,
        title: exchange_title,
        image: exchange_image,
    } = getExchangeData(exchange_name);

    return (
        <div className="max-w-[800px] mx-auto">
            <Typography allBold className="mt-[60px]" size="3xl">
                Vesting details
            </Typography>
            <div className="flex h-px flex-col justify-center items-start self-stretch bg-surface-level-two my-5"></div>

            {vesting_details.map(({ label, value }, index) => {
                const shouldApplyGradient = index % 2 === 0; // Apply gradient to even indices
                return (
                    <div
                        key={label + index + value}
                        className={`max-w-[780px] h-[60px] grid grid-cols-2 items-center px-8 ${
                            shouldApplyGradient
                                ? ''
                                : 'bg-gradient-to-r from-surface-level-one rounded-lg to-transparent'
                        }`}
                    >
                        <Typography variant="secondary" size="md">
                            {label}
                        </Typography>
                        <Typography size={is_mobile ? 'lg' : '2xl'} allBold>
                            {value}
                        </Typography>
                    </div>
                );
            })}
            <div className="my-[60px]">
                <Typography allBold size="3xl">
                    {is_dex ? 'Decentralized exchange' : 'Centralized exchange'}
                </Typography>
                <div className="flex h-px flex-col justify-center items-start self-stretch bg-surface-level-two my-5"></div>
                <div className="flex align-center gap-2">
                    <img
                        src={exchange_image}
                        alt={exchange_title}
                        className="w-[23px] h-[23px] rounded-lg"
                    />
                    <Typography allBold size="2xl">
                        {exchange_title}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default VestingDetails;
