import { useCallback, useEffect, useMemo, useState } from 'react';
import { HeaderProps } from '@decub8/ui';
import type { MediaVariant } from '@decub8/ui/dist/atoms/types';
import { useRouter } from 'next/router';

import { CONTENT, CONTRACT, DEFAULT_CHAIN_ID } from '@src/config';
import { useAppSelector } from '@src/hooks';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { LinkPosition, UserGroup } from '@src/ts/constants';
import { ExchangeTypes } from '@src/ts/types';
import { getPortfolioURL, handleLogout } from '@src/utils/user';
import { formatChainScanLink, shortenHex } from '@src/utils/web3';

import { useBurn } from './useBurn';
import { useNotification } from './useNotification';
import { useTier } from './useTier';
import {
    EXCHANGE_NAMES,
    getExchangeData,
    getInitialPathIndex,
    useStakingPools,
} from './utils';

export const useHeader = (): HeaderProps => {
    useStakingPools();
    const router = useRouter();
    const { user } = useAppSelector((state) => state?.auth) || {};
    const tier = useTier();
    const { dcb_burned } = useBurn(CONTENT.burnMechanism.hasBurnMechanism);

    const { connect, account, disconnect, wallet } = useWeb3Onboard();

    const nav_options = useMemo(() => {
        // Initial base options without Portfolio
        const base_options = [
            {
                name: `${
                    CONTENT.hasCapsFooter
                        ? 'Launchpad'.toUpperCase()
                        : 'Launchpad'
                }`,
                onOptionClick: () => router.push('/'),
                id: '0',
                desktop: true,
                relative_path: '/',
            },
            {
                name: `${
                    CONTENT.hasCapsFooter ? 'Staking'.toUpperCase() : 'Staking'
                }`,
                onOptionClick: () => router.push('/staking'),
                id: '1',
                desktop: true,
                relative_path: '/staking',
            },
            ...CONTENT.links
                .filter(({ position }) =>
                    [LinkPosition.Both, LinkPosition.Header].includes(position),
                )
                .map(({ name, link, dropdown_options }, id: number) => ({
                    name: name,
                    onOptionClick: link
                        ? () => {
                              link.toLowerCase().startsWith('http')
                                  ? window.open(link, '_blank')
                                  : router.push(link);
                          }
                        : undefined,
                    id: `${id + 2}`,
                    desktop: true,
                    relative_path: link,
                    dropdown_options: dropdown_options?.map(
                        ({ name, link }) => {
                            const is_exchange = EXCHANGE_NAMES.includes(
                                name as ExchangeTypes,
                            );
                            return {
                                name: is_exchange
                                    ? getExchangeData(name as ExchangeTypes)
                                          .title
                                    : name,
                                icon: (is_exchange
                                    ? getExchangeData(name as ExchangeTypes)
                                          .image
                                    : undefined) as MediaVariant,
                                onOptionClick: () =>
                                    window.open(link, '_blank'),
                                has_image_icon: is_exchange,
                            };
                        },
                    ),
                })),
        ];

        // Insert Portfolio after Launchpad if user is logged in
        if (user) {
            base_options.splice(1, 0, {
                name: `${
                    CONTENT.hasCapsFooter
                        ? 'Portfolio'.toUpperCase()
                        : 'Portfolio'
                }`,
                onOptionClick: () => router.push(getPortfolioURL(user)),
                id: '1',
                desktop: true,
                relative_path: '/portfolio',
            });
        }

        // Reassign ids to maintain order
        return base_options.map((option, index) => ({
            ...option,
            id: `${index}`,
        }));
    }, [router, user]);

    const [current, setCurrent] = useState(
        getInitialPathIndex(
            router.asPath,
            nav_options.map(({ relative_path }) => relative_path),
        ).toString(),
    );

    const handleConnectWalletClick = useCallback((): void => {
        if (account) {
            window.open(
                formatChainScanLink('Account', [DEFAULT_CHAIN_ID, account]),
            );
        } else {
            connect();
        }
    }, [account, connect]);

    const dropdown_options = useMemo(() => {
        const options = [
            {
                mediaClassName: 'text-accent1',
                icon: 'wallet' as MediaVariant,
                name: account ? shortenHex(account, 4) : 'Connect wallet',
                onOptionClick: handleConnectWalletClick,
                right_btn_text: account ? 'Disconnect' : undefined,
                rightBtnHandleClick: account ? () => disconnect() : undefined,
            },
            {
                mediaClassName: 'text-accent1',
                icon: 'user' as MediaVariant,
                name: user ? 'My profile' : 'Log in',
                onOptionClick: () => {
                    router.push(user ? '/account' : '/login');
                },
            },
            user
                ? {
                      mediaClassName: 'text-accent1',
                      icon: 'signout' as MediaVariant,
                      name: 'Log out',
                      onOptionClick: () => {
                          handleLogout(router);
                      },
                  }
                : {
                      mediaClassName: 'text-accent1',
                      icon: 'outline-plus' as MediaVariant,
                      name: 'Create account',
                      onOptionClick: () => {
                          router.push('/register');
                      },
                  },
        ];

        if (UserGroup.TeamAuthenticated.includes(user?.role)) {
            // Insert the object as the second last item in the array
            options.splice(options.length - 1, 0, {
                mediaClassName: 'text-accent1',
                icon: 'settings' as MediaVariant,
                name: 'Innovator’s hub',
                onOptionClick: () => {
                    window.open(`${CONTENT.innovatorUrl}`, '_blank');
                },
            });
        }
        return options;
    }, [user, UserGroup.TeamAuthenticated, account, router, wallet]);

    useEffect(() => {
        setCurrent(
            getInitialPathIndex(
                router.asPath,
                nav_options.map(({ relative_path }) => relative_path),
            ).toString(),
        );
    }, [router.asPath]);

    const notification = useNotification();

    return {
        has_smaller_link_gap: CONTENT.hasCutProjectCards,
        has_centered_links: CONTENT.hasCenteredHeaderLinks,
        smaller_logo_links_gap: CONTENT.hasCutProjectCards,
        current,
        dcb_burned,
        dropdown_options,
        logo: {
            desktop: CONTENT.logo.desktop,
            mobile: CONTENT.logo.mobile,
            onClick: () => router.push('/'),
        },
        nav_options,
        notification,
        tier,
        burn_label: `${CONTRACT.BaseToken[DEFAULT_CHAIN_ID].symbol} burned`,
        handleBurnClick: CONTENT.burnMechanism.hasBurnMechanism
            ? () => {
                  const newWindow = window.open('', '_blank');
                  newWindow.opener = null;
                  newWindow.location = CONTENT.burnMechanism.burnUrl;
              }
            : undefined,
        handleReferralClick: CONTENT.hasReferral
            ? user
                ? () => router.push('/referral')
                : () => router.push('/login')
            : undefined,
        setCurrent,
    };
};
