import { BigNumberish } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import { Duration } from 'dayjs/plugin/duration';

export const displayCountdown = (time_left: Duration): string =>
    `${String(time_left.hours() + time_left.days() * 24).padStart(
        2,
        '0',
    )}:${String(time_left.minutes()).padStart(2, '0')}:${String(
        time_left.seconds(),
    ).padStart(2, '0')}`;

export const bnFormatter = (
    num: BigNumberish,
    decimals = 18,
    decimalsToDisplay = 2,
): string => {
    const n = Number(formatUnits(num, decimals));
    return nFormatter(n, decimalsToDisplay);
};

export const nFormatter = (num: number, digits = 2): string => {
    const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'K' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'B' },
        { value: 1e12, symbol: 'T' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value;
        });
    return item
        ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
        : '0';
};

export const formatSig = (sig: string): string => {
    // Check for '0x' prefix and remove it
    if (sig.startsWith('0x')) {
        sig = sig.substring(2);
    }

    // Ensure the input signature is of the expected length (130 characters)
    if (sig.length !== 130) {
        throw new Error('Invalid signature length');
    }

    // Extract r, s, and v components
    const r = sig.substring(0, 64);
    let s = sig.substring(64, 128);
    let v = sig.substring(128, 130);

    // Convert s and v to BigInt
    let sBigInt = BigInt('0x' + s);
    const vBigInt = BigInt('0x' + v);
    const n = BigInt(
        '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFEBAAEDCE6AF48A03BBFD25E8CD0364141',
    );
    const halfN = n / BigInt(2);

    // Adjust s if it's in the upper half of the range
    if (sBigInt > halfN) {
        sBigInt = n - sBigInt;
        // Flip v from 27 to 28 or vice versa (0x1b to 0x1c or vice versa)
        v = vBigInt === BigInt(27) ? '1c' : '1b';
    }

    // Adjust v for malleable signatures (0/1 to 27/28)
    if (vBigInt === BigInt(0) || vBigInt === BigInt(1)) {
        v = (vBigInt + BigInt(27)).toString(16);
    }

    // Convert s back to a hex string and pad with zeros if necessary
    s = sBigInt.toString(16).padStart(64, '0');

    // Ensure r, s, and v are of the correct length
    if (r.length !== 64 || s.length !== 64 || v.length !== 2) {
        throw new Error('Invalid r, s, or v length after processing');
    }

    return '0x' + r + s + v;
};

export const formatBigNumber = (
    _n: BigNumberish,
    decimals = 18,
    decimals_to_display = 2,
): string => {
    const n = Number(formatUnits(_n, decimals));

    if (n >= 1000000000000) {
        return (n / 1000000000000).toFixed(decimals_to_display) + 'T';
    }
    if (n >= 1000000000) {
        return (n / 1000000000).toFixed(decimals_to_display) + 'B';
    }
    if (n >= 1000000) {
        return (n / 1000000).toFixed(decimals_to_display) + 'M';
    }
    if (n >= 1000) {
        return (n / 1000).toFixed(decimals_to_display) + 'K';
    }
    return n.toFixed(decimals_to_display);
};

export const generateSlug = (name: string) =>
    name.replace('|', '').replace('  ', ' ').toLowerCase().split(' ').join('-');

export function formatRelevantDecimals(num: number, precision: number): string {
    // Calculate the factor to shift the number to a whole number
    // based on the desired precision after the first significant digit
    const shift = Math.ceil(-Math.log10(num) + precision - 1);
    const shiftedNum = num * Math.pow(10, shift);

    // Round the number to remove any extra digits beyond the precision
    const rounded = Math.round(shiftedNum);

    // Shift the number back to its original scale
    const resultNum = rounded / Math.pow(10, shift);

    // Convert to string and ensure it has the correct number of decimals
    const resultStr = resultNum.toFixed(shift >= 0 ? shift : 0);

    return resultStr;
}

export function formatTime(seconds) {
    if (!seconds) {
        return 0;
    } else if (seconds < 60 && seconds > 0) {
        return `${seconds} second${seconds !== 1 ? 's' : ''}`;
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    } else if (seconds < 86400) {
        const hours = Math.floor(seconds / 3600);
        return `${hours} hour${hours !== 1 ? 's' : ''}`;
    } else if (seconds < 604800) {
        const days = Math.floor(seconds / 86400);
        return `${days} day${days !== 1 ? 's' : ''}`;
    } else if (seconds < 2592000) {
        const weeks = Math.floor(seconds / 604800);
        return `${weeks} week${weeks !== 1 ? 's' : ''}`;
    } else {
        const months = Math.floor(seconds / 2592000);
        return `${months} month${months !== 1 ? 's' : ''}`;
    }
}
